@import "normalize.css";
//@import "antd.default.css";
@import "~antd/lib/radio/style";
@import "~antd/lib/checkbox/style";
@import "~antd/lib/typography/style";
@import "~antd/lib/button/style";
@import "~antd/lib/result/style";
@import "~antd/lib/upload/style";
@import "~antd/lib/table/style";
@import "~antd/lib/date-picker/style";
@import "~antd/lib/select/style";
@import "~antd/lib/input/style";
@import "~antd/lib/input-number/style";
@import "~antd/lib/auto-complete/style";
@import "~antd/lib/modal/style";
@import "~antd/lib/message/style";
@import "~antd/lib/icon/style";
@import "~antd/lib/dropdown/style";
@import "~antd/lib/list/style";
@import "~antd/lib/form/style";
@import "~antd/lib/popover/style";
@import "~antd/lib/popconfirm/style";
@import "~antd/lib/progress/style";
@import "~antd/lib/tooltip/style";
@import "~antd/lib/slider/style";
@import "~antd/lib/spin/style";
@import "~antd/lib/empty/style";

@import "dark.theme";

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "IBMPlexSans", "ChevinPro", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
#webbot {
  margin: 0;
  line-height: 1.5 !important;
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }
  p {
    margin: 0;
    padding: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 500 !important;
    margin-top: 0;
  }

  .ant-table-content {
    @media only screen and (max-width: 768px) {
      overflow-x: auto;
    }
  }
  .ant-table table {
    @media only screen and (max-width: 768px) {
      min-width: max-content;
    }
  }

  .app {
    font-weight: 400;
    &-main {
      background: #fff;
      /*
            max-width: 1000px;
            margin: 0 auto;
            padding: 30px;
            @media only screen and (max-width: 479px) {
                padding: 20px 15px;
            }
             */
    }
    &__loading {
      margin: 0 auto !important;
      width: 100% !important;
    }
  }
  .drag-and-drops {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    flex-wrap: wrap;
    &__container {
      flex-basis: 250px;
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      padding: 5px 20px 20px 20px;
    }
    &__header,
    &__numbers {
      font-weight: 600;
      border-bottom: 1px solid #eee;
      padding: 15px;
    }
    &__numbers {
      border-color: transparent;
      text-align: center;
    }
    &__body {
      margin-top: 30px;
    }
    &__col {
      position: relative;
      &_left {
        flex-basis: 420px;
        max-width: 45%;
        @media only screen and (max-width: 479px) {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
      &_center {
        flex-basis: calc(100% - 420px - 420px);
        max-width: 10%;
        @media only screen and (max-width: 479px) {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
      &_right {
        flex-basis: 420px;
        max-width: 45%;
        @media only screen and (max-width: 479px) {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
    }
    &__item {
      padding: 7px 10px 9px;
      border: 2px solid #91d5ff;
      background-color: #ffffff;
      width: 100%;
      text-align: center;
      margin-top: 10px;
      user-select: none;
      position: relative;
      &-number {
        position: absolute;
        top: 50%;
        left: -15px;
        transform: translateY(-50%);
        font-weight: bold;
        transition: 0.3s;
        @media only screen and (max-width: 479px) {
          font-size: 12px;
          left: -25px;
        }
      }
      &_moving &-number {
        opacity: 0;
      }
    }
    &__arrow {
      margin: 0 auto;
      display: table;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: 42px;
      @media only screen and (max-width: 479px) {
        position: relative;
        left: 0;
        top: 0;
        margin-top: 5px;
        transform: rotate(90deg);
      }
    }
  }
  .ant-checkbox-group {
    width: 100%;
    & label {
      width: 100%;
      margin-top: 10px;
    }
  }

  .ant-result {
    margin: 0 auto;
  }
  .ant-result-title {
    line-height: 1.4;
    @media screen and (max-width: 479px) {
      font-size: 18px;
      line-height: 1.4;
    }
  }
  .p-loading {
    margin: 0 auto !important;
    display: table !important;
  }

  .result {
    &__picture {
      display: block;
      max-width: 100%;
    }
    &__text {
      margin-top: 25px;
      & * + * {
        margin-top: 15px;
      }
    }
    &-share {
      margin-top: 30px;
      &__title {
        font-weight: 500;
        font-size: 16px;
      }
      &__items {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 15px;
      }
      &__item {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
          transform: translateY(-3px);
        }
        &_vk {
          background-image: url("../images/vk.svg");
        }
        &_facebook {
          background-image: url("../images/facebook.svg");
          background-size: 90%;
        }
        &_twitter {
          background-image: url("../images/twitter.svg");
          background-size: 90%;
        }
      }
    }
  }

  .start {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 20px;
    &__instruction {
      margin-bottom: 20px;
      & *:last-child {
        margin-bottom: 0;
      }
      & blockquote {
        opacity: 1;
        & * {
          margin-top: 10px;
        }
      }
    }
    &__button {
      margin-top: 30px;
    }
  }
  .countdown {
    background: #fff;
    z-index: 2;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 10px 20px;
    display: table;
    width: 100%;
    & .ant-statistic-title {
      color: #000;
    }
    & .ant-statistic-content-value {
      font-weight: 400;
    }
  }
  .pages {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    gap: 20px;
    &_grid {
      grid-auto-columns: 1fr;
      grid-template-columns: 1fr 170px;
      grid-template-rows: auto auto;
      grid-template-areas:
        "a a"
        "b c";
      @media only screen and (max-width: 1024px) {
        grid-auto-flow: dense;
        grid-template-areas:
          "a a"
          "b b"
          "c c";
      }
    }
    &_grid .pages__col_top {
      grid-area: a;
      @media only screen and (max-width: 1024px) {
        grid-row: 1;
      }
    }
    &_grid .pages__col_left {
      grid-area: b;
      @media only screen and (max-width: 1024px) {
        grid-row: 3;
      }
    }
    &_grid .pages__col_right {
      grid-area: c;
      @media only screen and (max-width: 1024px) {
        grid-row: 2;
      }
    }
    &_grid .pages__col_right .pages__col-content {
      position: sticky;
      top: 30px;
      @media only screen and (max-width: 1024px) {
        top: 20px;
      }
    }
    &__instruction {
      & *:last-child {
        margin-bottom: 0;
      }
      & blockquote {
        opacity: 1;
      }
      & p + p {
        margin-top: 10px;
      }
    }
    &-progress {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      position: sticky;
      top: 0;
      left: 0;
      padding: 20px 0px;
      background-color: #fff;
      z-index: 999;
      .ant-progress-text {
        width: auto;
        margin-right: 8px;
      }
    }
  }
  .page {
    position: relative;
  }
  .questions {
    padding-bottom: 20px;
    &-item {
      &__title {
        &-important {
          color: red;
          vertical-align: top;
          display: inline-block;
          line-height: 1;
          margin-left: 0;
          position: absolute;
          bottom: 5px;
          right: -10px;
        }
        &-container {
          position: relative;
        }
      }
      &__checkbox {
        margin-left: 0;
      }
      &__radio {
        width: 100%;
        &-group {
          width: 100%;
          & > div {
            & > * {
              /** margin-top: 5px; */
            }
            margin-bottom: 5px !important;
          }
        }
      }
      &__help {
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }
  .question {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 20px;
    & + .question {
      margin-top: 20px;
    }
    &__header {
      & > *:last-child {
        margin-bottom: 0;
      }
    }
    &__image {
      display: block;
      max-width: 100%;
      margin-top: 10px;
      &_s {
        max-width: 50%;
      }
    }
    &__body {
      margin-top: 10px;
      & > *:last-child {
        margin-bottom: 0;
      }
    }
    &-item {
      &__distribution-points {
        margin-top: 10px;
      }
      &__distribution-point {
        &-header {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          flex-wrap: nowrap;
        }
        &-value {
          font-weight: bold;
        }
      }
    }
  }
  .back {
    margin: 0 auto !important;
    margin-top: 30px !important;
    display: table !important;
  }

  .ant-table-tbody tr {
    display: grid;
  }
  .app_dark {
    & .app-main {
      background: #202124;
    }
    & .start__instruction .ant-typography *,
    & .pages__instruction .ant-typography * {
      color: #fff !important;
      background: transparent !important;
    }
    & .question,
    & .start {
      border-color: #3c4043;
    }
    & .question .ant-typography,
    & .question p {
      color: #bdc1c6;
    }
    & .ant-progress-text {
      color: #fff;
    }
    & .ant-form label {
      font-size: 14px;
      color: #fff;
    }
    & .ant-typography.ant-typography-danger {
      color: #a61d24;
    }
    & .ant-table {
      background: transparent;
    }
    & .drag-and-drops__header,
    & .drag-and-drops__container {
      border-color: #3c4043;
    }
    & .drag-and-drops__item {
      background-color: #202124;
      border-color: #3c4043;
    }
    & .result svg {
      background: transparent !important;
    }
    & .ant-table-cell {
      color: #fff !important;
    }
    & .ant-table-tbody tr {
      color: #fff !important;
    }
  }
}

.ant-radio-inner::after {
  width: 10px;
  height: 10px;
}
.ant-progress-show-info .ant-progress-outer {
  margin-right: 0;
  padding-right: 0;
}
